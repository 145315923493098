.summaryBoard-date-container {
  margin: 6px 0 12px 0;
  text-align: right;
}

.summaryBoard-info-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.summaryBoard-info-item {
  padding: 32px;
  /* width: 23.5%; */
  width: 32.5%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #e9ecef;
}

.summaryBoard-info-text {
  font-size: 18px;
  color: #212529;
}

.summaryBoard-info-text--plus {
  font-size: 18px;
  color: red;
}

.summaryBoard-info-text--minus {
  font-size: 18px;
  color: blue;
}

.summaryBoard-info-text--strong {
  font-weight: 700;
  font-size: 20px;
}
